<template lang="pug">
v-card.Zmx-auto(max-width="360")
  v-list(subheader flat)
    template(v-for='(item, index) in catalog')
      v-list-item
        //- v-list-item-avatar(rounded size="48" color="grey darken-3")
          v-icon mdi-image
        v-list-item-content
          v-list-item-title {{item.name}}
          v-list-item-subtitle {{item.barcode}}
            //- template(v-if="item.name && item.name.length > 20") {{item.name}}
        v-list-item-action
          v-list-item-action-text.headline.ezcar  {{ item.price | numeral('0,0.[00]') }} ₸
      v-divider(v-if='index < catalog.length - 1' :key='index')

//- v-list
  template(v-for="(c,index) in orders")
    v-list-item(three-line)
      v-list-item-avatar(Zrounded)
        v-avatar.grey--text(color="white") ИВ
      v-list-item-content
        v-list-item-title Иванов Иван
        v-list-item-subtitle Молоко 2шт - 200тг, Молоко 2шт - 200тг, Молоко 2шт - 200тг, Молоко 2шт - 200тг, Молоко 2шт - 200тг
      v-list-item-action
        v-btn(icon): v-icon(color="grey lighten-1") mdi-menu-right
    v-divider(v-if="index < orders.length - 1" :key="index")
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {},

  // mixins: [decimalMixin, dictionaryMixin, billsAndCoinsMixin],

  props: {
    value: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data: () => ({
    selected: [2],
  }),

  computed: {
    ...mapState({
      catalog: (state) => state.tjtheme.catalog,
      cart: (state) => state.tjtheme.cart,
    }),
  },

  watch: {},

  methods: {
    ...mapActions({
    }),
  },
}
</script>
<style lang="stylus"></style>
